export const config = {
	Environment: "prod",
	ThunderstormModule: {
		appName: 'Kasper'
	},
	XhrHttpModule: {
		origin: "https://us-central1-elliq-env-map.cloudfunctions.net/api",
		timeout: 40000
	},
	ServicesModule: {
		services: [
			{
				name: 'Care Center',
				key: 'careCenter',
				link: 'https://ir-q-care-center.firebaseapp.com/',
				logo: require('@res/images/services-logos/care_center_logo.png')
			},
			{
				name: 'Package Manager',
				key: 'pm',
				link: 'https://ir-q-package-manager.firebaseapp.com/',
				logo: require('@res/images/services-logos/package_manager_logo.png')
			},
			{
				name: 'Q Studio',
				key: 'qs',
				link: 'https://ir-q-studio.firebaseapp.com/',
				logo: require('@res/images/services-logos/QS_logo.png')
			},
			{
				name: 'State Dashboard',
				key: 'sd',
				link: 'https://ir-q-state-dashboard.firebaseapp.com/',
				logo: require('@res/images/services-logos/state_dashboard_logo.png')
			},
			{
				name: 'Support',
				key: 'support',
				link: 'https://ir-q-support.elliq.co/',
				logo: require('@res/images/services-logos/support_logo.png')
			}
		]
	},
	LocaleModule: {
		defaultLocale: "en",
		locales: [
			{
				locale: "en",
				label: "Language_English",
				icon: "languages/en",
				texts: require(`./res/localization/en`)
			},
			{
				locale: "nl",
				label: "Language_Dutch",
				icon: "languages/nl",
				texts: require(`./res/localization/nl`)
			}
		],
	}
};
